import React from "react"
import Layout from "../containers/Layout"
import Navigation from "../containers/Navigation"
import SiteFooter from "../containers/SiteFooter"
import ModalStackProvider from "../providers/ModalStack"
import ThemeProvider from "../providers/Theme"

const NotFoundPage = ({ data }) => (
  <ThemeProvider>
    <ModalStackProvider>
      {modalStackDepth => (
        <Layout overlay={modalStackDepth > 0}>
          <Navigation
            siteTitle="StartupGen"
            menu={{
              items: [
                { key: "home", to: "/", label: "Home" },
                { key: "features", to: "/features/", label: "Features" },
                { key: "pricing", to: "/pricing/", label: "Pricing" }
              ]
            }}
          />
          <div className="container">
            <div className="row">
              <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                  <span class="display-1 d-block">404</span>
                  <div class="mb-4 lead">NOT FOUND</div>
                  <a href="/" class="btn btn-link">Forside</a>
                </div>
              </div>
              <h1>NOT FOUND</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </div>
          <SiteFooter />
        </Layout>
      )}
    </ModalStackProvider>
  </ThemeProvider>
)

export default NotFoundPage
